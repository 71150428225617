var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("샘플 문구")]),
        _vm._m(0),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "button",
            {
              staticClass: "button gray lg ml5",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" 닫기 ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_box" }, [
      _c("table", { staticClass: "tbl_row" }, [
        _c("b", [_vm._v("샘플문구 입니다. 복사해서 사용하세요 ")]),
        _c("br"),
        _c("br"),
        _vm._v(" Holiday Greetings "),
        _c("br"),
        _vm._v(" and Best Wishes for"),
        _c("br"),
        _vm._v(" the New Year "),
        _c("br"),
        _c("br"),
        _vm._v(" 지난해 보살펴 주신"),
        _c("br"),
        _vm._v(" 厚意에 깊이 感謝드리며 새해를 맞이하여"),
        _c("br"),
        _vm._v(" 萬福과 繁榮을 기원하나이다."),
        _c("br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }