<template>
  <div class="popup_wrap" style="width:500px;">
    <!--  popup_wrap  -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">샘플 문구</h1>
      <div class="content_box">
        <table class="tbl_row">
            <b>샘플문구 입니다. 복사해서 사용하세요 </b><br><br>
            Holiday Greetings <br>
            and Best Wishes for<br>
            the New Year <br>
            <br>
            지난해 보살펴 주신<br>
            厚意에 깊이 感謝드리며 새해를 맞이하여<br>
            萬福과 繁榮을 기원하나이다.<br>
        </table>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <button
          type="button"
          class="button gray lg ml5"
          @click="$emit('close')"
        >
         닫기
        </button><!-- 취소 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import { rootComputed } from '@/store/helpers'

export default {
  name: 'samplePop',
  data: function () {
    return {
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
  },
  methods: {
  }
}
</script>
